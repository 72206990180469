import Image from 'next/image';
import Link from 'next/link';
import style from './index.module.scss'
import arrow from '/public/assets/icons/icn-arrow-right.svg'

interface ICardWithImageBigProps {
    iconSrc: string;
    title: string;
    text: string;
    link: string;
    linkText: string;
    iconHeight: string;
    iconAlt?: string;
}

export const CardWithImageBig = ({ title, text, iconSrc, iconAlt, link, linkText, iconHeight }: ICardWithImageBigProps) => {
    return (
        <Link href={link}>
            <a rel='preload' className={`${style.card}`}>
                <div style={{ height: iconHeight, width: '100%', minHeight: iconHeight }} className={`${style.card__image}`}>
                    <Image loading='lazy' src={iconSrc} alt={iconAlt || title} layout={'fill'} />
                </div>
                <div className={`${style.card__content}`}>
                    <div className={`${style.card__title}`}>
                        {title}
                    </div>
                    <div className={`${style.card__text}`} dangerouslySetInnerHTML={{
                        // TODO: Possibly add sanitizer here
                        __html: text
                    }}>
                    </div>
                    <div className={`${style.card__link}`}>
                        {linkText}
                        <div className={`${style.card__link__arrow}`}>
                            <Image alt={'arrow'} src={arrow} width={'24px'} height={'24px'} />
                        </div>
                    </div>
                </div>
            </a>
        </Link >
    )
}
