import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { IArticle } from '@models/article.interface';
import { ButtonSecondaryWithIconRight } from '@components/shared/buttons/button-secondary-with-icon-right';
import { CardWithImageBig } from '@components/shared/card-types/card-with-image-big';

interface IBlogSectionProps {
    title: string;
    link: string;
    articles: IArticle[];
}


const BlogSection = ({ title, link, articles }: IBlogSectionProps) => {
    return (
        <section className={`${template.section} ${style.section}`}>
            <div className={`${template.container} ${template.container_small} ${style.container}`}>
                <div className={`${template.title} ${style.title}`}>
                    {title}
                </div>
            </div>
            <div className={`${template.container} ${template.container_large} ${style.cards__items}`}>
                {
                    articles.map((item: IArticle, index: number) => {
                        return(
                            <div key={index} className={`${style.cards__item}`}>
                                <CardWithImageBig
                                    title={item.header}
                                    text={item.description}
                                    link={`/blog/${item.slug}`}
                                    iconSrc={item.posterUrl}
                                    linkText={'Read more'}
                                    iconHeight={'208px'}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <div className={`${style.button}`}>
                    <ButtonSecondaryWithIconRight iconSrc={'/assets/icons/icn-arrow-right.svg'} text={'See all articles'} link={link}/>
                </div>
        </section>
    )
}

export default BlogSection;
